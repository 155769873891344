<template>
    <div class="province-city">
        <div class="header" v-show="enableHeader">
            选择城市信息
        </div>

        <div class="selected primary-selected" v-if="hasPrimaryCity">
            <span class="selected-tip">已选取：</span>
            <div class="selected-option-box primary" v-if="hasSelectedCity">
                <div class="selected-option">
                    <span>{{selectedCity[0].text}}</span>
                    <span class="option-remove" @click="removeSelectedCity(selectedCity[0])">
                        <i class="el-icon-close"></i>
                    </span>
                </div>
            </div>
            <div class="selected-option-box normal" v-if="hasSelectedCity">
                <div class="selected-option" v-for="(item, index) in selectedCity.slice(1, selectedCity.length)" :key="index">
                    <i class="set-primary" @click="handleSetPrimary(index + 1)">设为主城市</i>
                    <span>{{item.text}}</span>
                    <span class="option-remove" @click="removeSelectedCity(item)">
                        <i class="el-icon-close"></i>
                    </span>
                </div>
            </div>

            <span class="default-selected" v-if="!hasSelectedCity">全国</span>
        </div>

        <div class="selected" v-else>
            <span class="selected-tip">已选取：</span>
            <div class="selected-option-box" v-if="hasSelectedCity">
                <div class="selected-option" v-for="(item, index) in selectedCity" :key="index">
                    <span>{{item.text}}</span>
                    <span class="option-remove" @click="removeSelectedCity(item)">
                        <i class="el-icon-close"></i>
                    </span>
                </div>
            </div>
            <span class="default-selected" v-if="!hasSelectedCity">全国</span>
        </div>
        <div v-show="!isForeignMode" class="hot-cities clearfix">
            <h3 class="title">
                热门城市
            </h3>
            <div class="item-wrap" v-for="(city, index) in hotCities" :key="'hotCity_' + index">
                <span class="item" :class="{active: checkCityIfSelected(city.id)}" @click="selectCity(city)">
                    {{city.text}}
                </span>
            </div>
        </div>
        <div v-show="isForeignMode" class="hot-cities clearfix">
            <h3 class="title">
                热门国家
            </h3>
            <div class="item-wrap" v-for="(country, index) in hotForeignData" :key="'hotForeignData_' + index">
                <span class="item" :class="{active: checkCityIfSelected(country.id)}" @click="selectCity(country)">
                    {{country.text}}
                </span>
            </div>
        </div>
        <div v-show="!isForeignMode" class="detail clearfix">
            <h3 class="title">
                全部省份
            </h3>
            <div v-for="(provinceColumnsItem, index) in provinceColumns" :key="index">
                <div class="line clearfix">
                    <div class="item-wrap" v-for="subProvinceColumnsItem in provinceColumnsItem" :key="subProvinceColumnsItem.id">
                        <span v-if="subProvinceColumnsItem && subProvinceColumnsItem.id" class="item" :class="{active: selectedProvince && (subProvinceColumnsItem.id == selectedProvince.id)}" @click="selectProvince(subProvinceColumnsItem, index)">
                            <span>{{subProvinceColumnsItem.text}}</span>
                            <!--<icon name="edit" :k-text="subProvinceColumnsItem.selectedCityCount" v-show="subProvinceColumnsItem.selectedCityCount > 0"></icon>-->
                        </span>
                    </div>
                </div>
                <div class="city-line clearfix" v-if="selectedProvinceColumn !== null && selectedProvince && index === currentIndex">
                    <div class="item-wrap" v-for="(selectedProvinceCityItem, index) in selectedProvinceCity" :key="'selectedProvinceCity_' + index">
                        <span class="item" :class="{active: checkCityIfSelected(selectedProvinceCityItem.id)}" @click="selectCity(selectedProvinceCityItem)">
                            {{selectedProvinceCityItem.text}}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="isForeignMode" class="detail clearfix">
            <h3 class="title">
                其他国家
            </h3>
            
            <div class="foreign-list city-line clearfix">
                <div class="item-wrap" v-for="(country, index) in foreignData" :key="'foreignData_' + index">
                    <span class="item" :class="{active: checkCityIfSelected(country.id)}" @click="selectCity(country)">
                        {{country.text}}
                    </span>
                </div>
            </div>
        </div>

        <div v-show="!isForeignMode" class="foreign-btn" @click="toogleForeignMode">
            + 海外国家
        </div>
        <div v-show="isForeignMode" class="foreign-btn" @click="toogleCityMode">
            + 国内城市
        </div>

        <div class="footer" v-show="enableFooter">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { getHotCities, getCityTextById, getProvinceById, getProvinceTextById, getCityIdByText, getProvinceIdByText, getProvince, getCitiesByProvince, getProvinceByCity, getForeignHotData, getForeignData } from '#/js/util/provinceCity.js';

    function processProvince(item){
        Vue.set(item, 'selected', false);
        Vue.set(item, 'selectedCityCount', 0);
        // item.selected = ko.observable(false);
        // item.selectedCityCount = ko.observable(0);
    };

    // 地区选取控件
    export default {
        name: 'ProvinceCity',
        components: {
            // Icon
        },
        props: {
            gridLength: {
                type: Number,
                default: 12
            },
            enableMultipeSelected: {
                type: Boolean,
                default: false
            },
            multipeLimit: {
                type: Number,
                default: 0
            },
            enableHeader: {
                type: Boolean,
                default: false,
            },
            enableFooter: {
                type: Boolean,
                default: false,
            },
            hasPrimaryCity: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                provinceColumns: [],
                provinces: getProvince(),
                provinceColumnMap: {},
                selectedProvince: null,
                selectedCity: [],
                selectedProvinceColumn: null,
                selectedProvinceCity: [],
                hotCities: getHotCities(),
                currentIndex: 0,
                isForeignMode: false,
                hotForeignData: getForeignHotData(),
                foreignData: getForeignData()
            }
        },

        mixins: [
        ],

        created() {
            this.hotCities.forEach((city) =>{
                this.$set(city, 'selected', false);
            })
            this.initGrid();
        },

        computed: {
            hasSelectedCity() {
                return this.selectedCity.length > 0;
            }
        },

        watch: {
            selectedCity(val) {
                if(val.length == 0) {
                    this.$emit('city-select', {id: 0, selected: true, text: '全国',silence:true});
                }
            }
        },

        methods: {
            initGrid(){
                let gridLength = this.gridLength,
                    provinces = this.provinces,
                    provinceKeys = Object.keys(provinces),
                    provinceCount = provinceKeys.length,
                    columnCount = Math.ceil(provinceCount / gridLength),
                    provinceColumns = this.provinceColumns,
                    provinceColumnMap = this.provinceColumnMap = {},
                    idx = 0;
                for(let i = 0; i < columnCount; i++){
                    let column = [];
                    for(let j = 0; j < gridLength; j++){
                        idx = i * gridLength + j;
                        let key = provinceKeys[idx];
                        provinceColumnMap[key] = i;
                        let item = getProvinceById(key);
                        this.$set(item, 'selected', false);
                        this.$set(item, 'selectedCityCount', 0);
                        column.push(item);
                    }
                    provinceColumns.push(column);
                }
            },
            handleSetPrimary(index) {
                console.log(index);
                console.log(this.selectedCity);
                let primary = this.selectedCity.splice(index, 1);
                console.log(this.selectedCity);
                this.selectedCity = primary.concat(this.selectedCity);
                console.log(this.selectedCity);
            },
            removeSelectedCity(item, force = false, silence = false){
                if(this.enableMultipeSelected && force !== true){
                    let selectedCity = this.selectedCity;
                    selectedCity.forEach((city, idx) => {
                        if(city.id === item.id){
                            city.selected = false;
                            this.selectedCity.splice(idx, 1);
                            return false;
                        }
                    })
                } else {
                    item && (item.selected = false);
                    this.selectedCity = [];
                    if(silence !== true){
                        this.$emit('area-clear', silence);
                    }
                }
            },
            clearSelected(silence = false){
                this.clearSelectedProvince();
                this.clearSelectedCity();
                this.$emit('area-clear', silence);
            },
            clearSelectedCity(){
                this.selectedCity.forEach(city => {
                    city.selected = false;
                })
                this.hotCities.forEach(city => {
                    city.selected = false;
                })
                this.selectedCity = [];
            },
            clearSelectedProvince(){
                if(this.selectedProvince) {
                    this.selectedProvince.selectedCityCount = 0;
                    this.selectedProvince.selected = false;
                }
                this.selectedProvince = null;
            },

            selectProvince(item, index){
                // if(!this.enableMultipeSelected){
                //     this.clearSelectedCity();
                //     this.clearSelectedProvince();
                // }
                item.selected = true;
                this.selectedProvince = item;
                let cities = getCitiesByProvince(item.id);
                cities.forEach((city) =>{
                    city.selected = false;
                })
                // TODO：优化，不必每一次都刷新
                this.selectedProvinceCity = cities;
                this.selectedProvinceColumn = this.provinceColumnMap[item.id];
                this.currentIndex = index;
                return false;
            },

            selectCity(item, ev, silence){
                let itemIndex = -1;
                this.selectedCity.forEach((city, index) => {
                    if(city.id == item.id) {
                        itemIndex = index;
                    }
                })

                if(itemIndex > -1) {
                    this.selectedCity.splice(itemIndex, 1);
                    return false;
                }

                if(!this.enableMultipeSelected){
                    this.clearSelectedCity();
                }

                if(!this.enableMultipeSelected && !this.isForeignMode){
                    let province = getProvinceByCity(item.id);
                    this.clearSelectedCity();
                    let selectedProvince = this.selectedProvince;
                    if(selectedProvince) {
                        selectedProvince.selected = false;
                    }
                }
                if(this.enableMultipeSelected && this.multipeLimit > 0 && this.selectedCity.length >= this.multipeLimit) {
                    shortTips(`最多选择${this.multipeLimit}个城市`);
                    return false;
                }

                item.selected = true;
                this.selectedCity.push(item);
                if(silence !== true){
                    this.$emit('city-select', item);
                }
                return false;
            },
            //外部选取城市, 海外国家暂不支持从外部选择
            selectCityOutside(cid){
                let province = getProvinceByCity(cid);
                if(province.id){
                    let provinceColumns = this.provinceColumns;
                    let item;
                    provinceColumns.forEach((column) =>{
                        column.forEach((v) =>{
                            if(v.id === province.id){
                                item = v;
                                return false;
                            }
                        })
                    })
                    if(item){
                        this.selectProvince(item);
                        let selectedProvinceCity = this.selectedProvinceCity;
                        let cityItem;
                        selectedProvinceCity.forEach((city) => {
                            if(city.id === cid){
                                cityItem = city;
                                return false;
                            }
                        })
                        if(cityItem){
                            this.selectCity(cityItem, null, true);
                        }
                    }
                }
                this.hotCities.forEach(city => {
                    if(cid === city.id){
                        city.selected = true;
                        return false;
                    }
                })
                let foreignItem;
                this.foreignData.forEach((foreign) =>{
                    if(cid === foreign.id){
                        foreignItem = foreign;
                        return false;
                    }
                })
                if(foreignItem) {
                    this.selectCity(foreignItem, null, true);
                }
            },
            checkCityIfSelected(id) {
                function isInArr(item) {
                    return item.id == id;
                }
                return this.selectedCity.some(isInArr);
            },
            setCheckedCityText(cityArr){
                let selectedCity = [];
                cityArr.forEach((item)=>{
                    let cityText = getCityTextById(item);
                    let cityObj = {
                        id: item,
                        selected: true,
                        text: cityText
                    }
                    selectedCity.push(cityObj)
                })
                this.selectedCity = selectedCity;
                this.$emit('handleCitySelect');
            },
            confirm(){
                return this.$emit('confirm');
            },
            cancel (){
                return this.$emit('cancel');
            },
            toogleForeignMode() {
                this.isForeignMode = true;
            },
            toogleCityMode() {
                this.isForeignMode = false;
            }
        }
    };
</script>

<style lang="scss">
    .province-city {
        line-height: 24px;

        .foreign-btn {
            margin: 20px 20px 0;
            height: 38px;
            line-height: 38px;
            text-align: center;
            font-size: 14px;
            color: #666;
            border: 1px dashed #666660;
            cursor: pointer;
        }

        .header{
            margin-bottom: 30px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
            font-weight: bold;
            font-size: 18px;
            color: #333;
            border-bottom: 1px solid #ececec;
        }

        .footer{
            display: table;
            margin: 40px auto 20px auto;

            /deep/ .el-button + .el-button{
                margin-left: 35px;
            }
        }

        .selected {
            padding: 0 20px 10px;

            &-tip {
                float: left;
            }
        }

        .selected-option-box {
            overflow: hidden;
            cursor: default;

            &.primary {
                padding-left: 22px;
                border-color: $primary;

                .selected-option {
                    &::after {
                        content: '主';
                        position: absolute;
                        top: -1px;
                        left: -22px;
                        bottom: -1px;
                        width: 22px;
                        text-align: center;
                        line-height: 24px;
                        font-size: 12px;
                        color: #fff;
                        background-color: $primary;
                    }
                }
            }

            &.normal {
                margin-top: 9px;
                padding-left: 56px;

                .selected-option {
                    min-width: 64px;
                    margin-bottom: 22px;

                    .set-primary {
                        display: none;
                        position: absolute;
                        top: 26px;
                        left: 0;
                        right: 0;
                        height: 18px;
                        line-height: 18px;
                        text-align: center;
                        font-size: 12px;
                        color: $primary;
                        background-color: #f5f5f5;
                        cursor: pointer;
                    }

                    &:hover {
                        border-color: $primary;

                        .set-primary {
                            display: block;
                        }
                    }
                }
            }
        }

        .selected-option {
            position: relative;
            display: inline-block;
            margin-right: 15px;
            margin-bottom: 6px;
            border: 1px solid #d3d3d3;
            color: $primary;
            padding: 0 23px 0 7px;
            vertical-align: top;

            .option-remove {
                position: absolute;
                width: 23px;
                right: 0;
                top: 0;
                text-align: center;
                font-size: 10px;
                color: #ccc;
                cursor: pointer;
            }
        }

        .default-selected {
            display: inline-block;
            border: 1px solid #d3d3d3;
            color: $primary;
            padding: 0 7px;
            vertical-align: top;
        }

        .title {
            font-size: 18px;
            color: #333;
            margin-bottom: 0;
            margin-top: 25px;
            padding-left: 20px;
            padding-right: 20px;
        }

        .item-wrap {
            float: left;
            width: 82px;
            margin-top: 15px;
        }

        .item {
            padding: 0 10px;
            display: inline-block;
            white-space: nowrap;
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }

        .line {
            position: relative;
            padding: 0 10px;

            .item {
                border-bottom: 1px solid transparent;
            }

            .active {
                color: $primary;
                border-bottom-color: $primary;
            }
        }

        .city-line {
            background-color: #f6f6f6;
        }

        .hot-cities .title {
            padding: 0 10px;
        }

        .city-line,
        .hot-cities {
            padding: 0 10px 15px;
            width: 100%;
            .item-wrap {
                width: auto;
                margin-right: 30px;
            }

            .active {
                color: #fff;
                background-color: $primary;
            }
        }

        .foreign-list {
            margin-top: 15px;
            max-height: 240px;
            overflow-y: scroll;
        }
    }

</style>
