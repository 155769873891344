var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "province-city" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.enableHeader,
            expression: "enableHeader",
          },
        ],
        staticClass: "header",
      },
      [_vm._v("\n        选择城市信息\n    ")]
    ),
    _vm.hasPrimaryCity
      ? _c("div", { staticClass: "selected primary-selected" }, [
          _c("span", { staticClass: "selected-tip" }, [_vm._v("已选取：")]),
          _vm.hasSelectedCity
            ? _c("div", { staticClass: "selected-option-box primary" }, [
                _c("div", { staticClass: "selected-option" }, [
                  _c("span", [_vm._v(_vm._s(_vm.selectedCity[0].text))]),
                  _c(
                    "span",
                    {
                      staticClass: "option-remove",
                      on: {
                        click: function ($event) {
                          return _vm.removeSelectedCity(_vm.selectedCity[0])
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-close" })]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.hasSelectedCity
            ? _c(
                "div",
                { staticClass: "selected-option-box normal" },
                _vm._l(
                  _vm.selectedCity.slice(1, _vm.selectedCity.length),
                  function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "selected-option" },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "set-primary",
                            on: {
                              click: function ($event) {
                                return _vm.handleSetPrimary(index + 1)
                              },
                            },
                          },
                          [_vm._v("设为主城市")]
                        ),
                        _c("span", [_vm._v(_vm._s(item.text))]),
                        _c(
                          "span",
                          {
                            staticClass: "option-remove",
                            on: {
                              click: function ($event) {
                                return _vm.removeSelectedCity(item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-close" })]
                        ),
                      ]
                    )
                  }
                ),
                0
              )
            : _vm._e(),
          !_vm.hasSelectedCity
            ? _c("span", { staticClass: "default-selected" }, [_vm._v("全国")])
            : _vm._e(),
        ])
      : _c("div", { staticClass: "selected" }, [
          _c("span", { staticClass: "selected-tip" }, [_vm._v("已选取：")]),
          _vm.hasSelectedCity
            ? _c(
                "div",
                { staticClass: "selected-option-box" },
                _vm._l(_vm.selectedCity, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "selected-option" },
                    [
                      _c("span", [_vm._v(_vm._s(item.text))]),
                      _c(
                        "span",
                        {
                          staticClass: "option-remove",
                          on: {
                            click: function ($event) {
                              return _vm.removeSelectedCity(item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-close" })]
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          !_vm.hasSelectedCity
            ? _c("span", { staticClass: "default-selected" }, [_vm._v("全国")])
            : _vm._e(),
        ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isForeignMode,
            expression: "!isForeignMode",
          },
        ],
        staticClass: "hot-cities clearfix",
      },
      [
        _c("h3", { staticClass: "title" }, [
          _vm._v("\n            热门城市\n        "),
        ]),
        _vm._l(_vm.hotCities, function (city, index) {
          return _c(
            "div",
            { key: "hotCity_" + index, staticClass: "item-wrap" },
            [
              _c(
                "span",
                {
                  staticClass: "item",
                  class: { active: _vm.checkCityIfSelected(city.id) },
                  on: {
                    click: function ($event) {
                      return _vm.selectCity(city)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(city.text) + "\n            "
                  ),
                ]
              ),
            ]
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isForeignMode,
            expression: "isForeignMode",
          },
        ],
        staticClass: "hot-cities clearfix",
      },
      [
        _c("h3", { staticClass: "title" }, [
          _vm._v("\n            热门国家\n        "),
        ]),
        _vm._l(_vm.hotForeignData, function (country, index) {
          return _c(
            "div",
            { key: "hotForeignData_" + index, staticClass: "item-wrap" },
            [
              _c(
                "span",
                {
                  staticClass: "item",
                  class: { active: _vm.checkCityIfSelected(country.id) },
                  on: {
                    click: function ($event) {
                      return _vm.selectCity(country)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(country.text) +
                      "\n            "
                  ),
                ]
              ),
            ]
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isForeignMode,
            expression: "!isForeignMode",
          },
        ],
        staticClass: "detail clearfix",
      },
      [
        _c("h3", { staticClass: "title" }, [
          _vm._v("\n            全部省份\n        "),
        ]),
        _vm._l(_vm.provinceColumns, function (provinceColumnsItem, index) {
          return _c("div", { key: index }, [
            _c(
              "div",
              { staticClass: "line clearfix" },
              _vm._l(provinceColumnsItem, function (subProvinceColumnsItem) {
                return _c(
                  "div",
                  { key: subProvinceColumnsItem.id, staticClass: "item-wrap" },
                  [
                    subProvinceColumnsItem && subProvinceColumnsItem.id
                      ? _c(
                          "span",
                          {
                            staticClass: "item",
                            class: {
                              active:
                                _vm.selectedProvince &&
                                subProvinceColumnsItem.id ==
                                  _vm.selectedProvince.id,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.selectProvince(
                                  subProvinceColumnsItem,
                                  index
                                )
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(subProvinceColumnsItem.text)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
            _vm.selectedProvinceColumn !== null &&
            _vm.selectedProvince &&
            index === _vm.currentIndex
              ? _c(
                  "div",
                  { staticClass: "city-line clearfix" },
                  _vm._l(
                    _vm.selectedProvinceCity,
                    function (selectedProvinceCityItem, index) {
                      return _c(
                        "div",
                        {
                          key: "selectedProvinceCity_" + index,
                          staticClass: "item-wrap",
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "item",
                              class: {
                                active: _vm.checkCityIfSelected(
                                  selectedProvinceCityItem.id
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectCity(
                                    selectedProvinceCityItem
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(selectedProvinceCityItem.text) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isForeignMode,
            expression: "isForeignMode",
          },
        ],
        staticClass: "detail clearfix",
      },
      [
        _c("h3", { staticClass: "title" }, [
          _vm._v("\n            其他国家\n        "),
        ]),
        _c(
          "div",
          { staticClass: "foreign-list city-line clearfix" },
          _vm._l(_vm.foreignData, function (country, index) {
            return _c(
              "div",
              { key: "foreignData_" + index, staticClass: "item-wrap" },
              [
                _c(
                  "span",
                  {
                    staticClass: "item",
                    class: { active: _vm.checkCityIfSelected(country.id) },
                    on: {
                      click: function ($event) {
                        return _vm.selectCity(country)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(country.text) +
                        "\n                "
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isForeignMode,
            expression: "!isForeignMode",
          },
        ],
        staticClass: "foreign-btn",
        on: { click: _vm.toogleForeignMode },
      },
      [_vm._v("\n        + 海外国家\n    ")]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isForeignMode,
            expression: "isForeignMode",
          },
        ],
        staticClass: "foreign-btn",
        on: { click: _vm.toogleCityMode },
      },
      [_vm._v("\n        + 国内城市\n    ")]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.enableFooter,
            expression: "enableFooter",
          },
        ],
        staticClass: "footer",
      },
      [
        _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.confirm } },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }